<template>
    <v-card class="elevation-12">
        <!-- Header -->
        <v-toolbar color="light-green" dark flat>
            <v-toolbar-title>Вход</v-toolbar-title>
            <v-spacer></v-spacer>
        </v-toolbar>
        <!-- Body -->
        <v-card-text>
            <v-form>
                <v-alert
                  v-if="!_.isNil(summaryError)"
                  dense
                  type="error"
                >
                    {{ summaryError }}
                </v-alert>

                <v-text-field 
                    v-model="$v.form.login.$model"
                    :error-messages="getErrors('form.login')"
                    label="Логин" 
                    name="login" 
                    prepend-icon="mdi-account" 
                    type="text" 
                    placeholder=" "
                    autofocus="autofocus" 
                    color="light-green"
                ></v-text-field>

                <v-text-field 
                    v-model="$v.form.password.$model"
                    :error-messages="getErrors('form.password')"
                    id="password" 
                    label="Пароль" 
                    name="password" 
                    prepend-icon="mdi-lock" 
                    type="password" 
                    placeholder=" " 
                    color="light-green"
                ></v-text-field>
            </v-form>
        </v-card-text>
        <!-- Footer -->
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn dark color="light-green" :loading="waiting" @click.prevent.stop="login">
                Войти
            </v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
import { errorMixin } from '@/mixins/formMixin'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'

export default {
    name: 'LoginForm',
    mixins: [errorMixin, validationMixin],
    data() {
        return {
            form: {
                login: '',
                password: ''
            },
            waiting: false
        };
    },
    validations: {
        form: {
            login: {
                required
            },
            password: {
                required
            }
        }
    },
    methods: {
        async login() {
            this.waiting = true;
            this.serverErrors = null;

            if (this.validate()) {
                const { success, data, error } = await this.$store.dispatch('user/login', this.form);

                if (success) {
                    await this.$store.dispatch('user/setSession', {
                        access_token: data.access_token,
                        profile: data.profile
                    });
                    this.$emit('success', 1);
                }
                else
                    this.serverErrors = error;
            }

            this.waiting = false;
            return;
        }
    }

}
</script>
<style scoped lang="scss">
.login-form {
    .login-form__show-password-submit-group {
        @media screen and (max-width: 667px) {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: space-between;
            align-items: center;
        }
    }
}
</style>