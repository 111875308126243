import Auth from '@/store/classes/auth'
import Crud from '@/store/classes/crud'

const auth = new Auth()
const crud = new Crud({
    module: 'user'
})

export default {
    namespaced: true,
    state: {
        ...auth.state,
        filter: {
            active: null,
            id: null,
            role: null,
            name: null,
            login: null
        },
        roles: [
            {
                value: 'admin',
                text: 'Администратор'
            },
            {
                value: 'support',
                text: 'Сотрудник техподдержки'
            }
        ]
    },
    mutations: {
        ...auth.mutations,
        ...crud.mutations
    },
    actions: {
        ...auth.actions,
        ...crud.actions
    },
    getters: {
        ...auth.getters
    }
}