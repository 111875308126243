export default [{
        module: 'user',
        name: ['аккаунты', 'аккаунта']
    },
    {
        module: 'partner',
        name: ['партнеры', 'партнера']
    },
    {
        module: 'code',
        name: ['ключи', 'ключа']
    },
    {
        module: 'short_code',
        name: ['коды', 'кода']
    },
    {
        module: 'license_type',
        name: ['тип лицензии', 'типа лицензии']
    },
    {
        module: 'license',
        name: ['лицензии', 'лицензии']
    },
    {
        module: 'order',
        name: ['заказы', 'заказа']
    },
    {
        module: 'version',
        name: ['версии', 'версии']
    },
    {
        module: 'stats',
        name: ['статистика', 'статистика']
    }
]