import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';

import _ from 'lodash'
Object.defineProperty(Vue.prototype, '_', { value: _ });

// Constants
import constants from './constants'
Object.defineProperty(Vue.prototype, '$const', { value: constants });

Vue.config.productionTip = false

// App init function
const init = async () => {
    // Restore state from local storage
    await store.dispatch('user/restoreSession');
    // Fetch app data
    store.dispatch('license_type/fetchSubjects');
    store.dispatch('product/fetch');
    // Init vue app
    const app = new Vue({
        router,
        store,
        vuetify,
        render: h => h(App)
    }).$mount('#app')
    store.$app = app;
}

// Init application
init();