<template>
    <v-row align="center" justify="center" dark>
        <v-col cols="12" sm="8" md="4">
            <login-form @success="onLoginSuccess" />
        </v-col>
    </v-row>
</template>
<script>
import LoginForm from '@/components/auth/LoginForm.vue'
export default {
    name: 'Login',
    components: { LoginForm },
    methods: {
        onLoginSuccess() {
            return this.$router.push('/order').catch(() => {});
        }
    }
}
</script>