import Vue from 'vue'
import Crud from '@/store/classes/crud'
const crud = new Crud({
    module: 'product'
})

export default {
    namespaced: true,
    state: {
        items: []
    }, 
    mutations: {
        SET_ITEMS(state, data) {
            Vue.set(state, 'items', data);
        }
    },
    actions: {
        async fetch({commit}) {
            const items = await crud.actions.list();
            commit('SET_ITEMS', items?.data || []);
            return items?.data || [];
        }
    }
}