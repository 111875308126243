<template>
    <v-row align="center" justify="center" dark>
        <v-col cols="12" sm="10" md="6">
            <register-form />
        </v-col>
    </v-row>
</template>
<script>
import RegisterForm from '@/components/auth/RegisterForm.vue'
export default {
    name: 'Activation',
    components: { RegisterForm }
}
</script>