import Crud from '@/store/classes/crud'
const crud = new Crud({
    module: 'version'
})

export default {
    namespaced: true,
    state: {
        ...crud.state,
        filter: {
            platform: '',
            current: false,

        },
        platforms: [{
            text: 'Windows',
            value: 'windows'
        },{
            text: 'MacOS',
            value: 'macos'
        },{
            text: 'Linux',
            value: 'linux'
        },{
            text: 'Android',
            value: 'android'
        },{
            text: 'iOS',
            value: 'ios'
        },{
            text: 'Сетевая версия',
            value: 'net'
        }],
        types: {
            linux: [{
                text: 'DEB',
                value: 'deb'
            },{
                text: 'RPM',
                value: 'rpm'
            }]
        }
    },
    mutations: {
        ...crud.mutations
    },
    actions: {
        ...crud.actions
    },
    getters: {
        ...crud.getters
    }
}