// Error mixin
const errorMixin = {
    data() {
        return {
            serverErrors: null
        };
    },
    computed: {
        summaryError() {
          return _.get(this, 'serverErrors.summary', null);  
        }
    },
    methods: {
        getErrors(field, custom_validators) {
            const errors = []
            if (!_.get(this, `$v.${field}.$dirty`, false)) return errors
            // Required
            if(_.get(this, `$v.${field}.required`, null) === false)
                errors.push('Обязательно');
            // minLength
            if(_.get(this, `$v.${field}.minLength`, null) === false)
            {
                const minValue = _.get(this, `$v.${field}.$params.minLength.min`, '');
                errors.push(`Не менее ${minValue} символов`);
            }
            // Email
            if(_.get(this, `$v.${field}.email`, null) === false)
                errors.push('Неверный e-mail')

            // Custom
            if(!_.isNil(custom_validators))
            {
                _.forOwn(custom_validators, (value, key) => {
                    if(_.get(this, `$v.${field}.${key}`, null) === false)
                        errors.push(value);
                });
            }

            // Server error
            const server_error = _.get(this, `serverErrors.${_.last(_.toPath(field))}`, null);
            if(!_.isNil(server_error))
                errors.push(_.isArray(server_error) ? _.get(server_error, '0', '') : server_error);

            return errors
        },
        validate() {
            this.$v.$touch();
            return !this.$v.$invalid;
        }
    }
}
// Save mixin
const saveMixin = {
    data() {
        return {
            waiting: {
                save: {
                    back: false,
                    update: false
                }
            }
        };
    },
    computed: {
        action() {
            return _.get(this, 'model.id', 0) <= 0 ? 'create' : 'update';
        }
    },
    methods: {
        back() {
            return this.$emit('back', 1);
        },
        prepareForm(form) {
            return form;
        },
        async save(back) {
            const waiting_type = back === true ? 'back' : 'update';

            this.waiting.save[waiting_type] = true;
            this.serverErrors = null;

            if (this.validate()) {
                const form = this.prepareForm(this.form);
                const { success, error, data } = await this.$store.dispatch(`${this.storeModule}/${this.action}`, form);

                if(success)
                {
                    this.$emit('success', {
                        goBack: back,
                        ...data
                    });
                }
                else
                    this.serverErrors = error;
            }

            this.waiting.save[waiting_type] = false;
            return;
        }
    },
    async created() {
        if(this.action === 'update')
            _.assign(this.form, this.model);
    }
}

export { errorMixin, saveMixin }
