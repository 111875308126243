import ApiHelper from '@/helpers/ApiHelper.js'
import Crud from '@/store/classes/crud'
const crud = new Crud({
    module: 'license'
})
const md5 = require('js-md5')

export default {
    namespaced: true,
    state: {
        filter: {
            active: null,
            id: null,
            created_at: null,
            code: null,
            license_type_id: null,
            name: null,
            company: null,
            email: null,
            address: null
        }
    },
    mutations: {
        ...crud.mutations
    },
    actions: {
        ...crud.actions,
        async view(context, data) {
            return ApiHelper.get(process.env.VUE_APP_LMS_SERVER_HOST + '/api/license/view', {
                hash: md5('owner_' + data)
            }, {
                api_key: process.env.VUE_APP_LMS_SERVER_API_KEY
            });
        },
        async checkCode(context, data) {
            return ApiHelper.get('/api/code/check', data);
        },
        async activate(context, params) {
            return ApiHelper.post('/api/code/activate', {
                owner_id: _.get(context, 'rootState.user.profile.id', '0'),
                owner_login: _.get(context, 'rootState.user.profile.login', ''),
                ...params.activation
            }, 
            {
                hash: params.hash
            });
        }
    }
}