import store from '@/store/index.js'
import axios from 'axios'

const GET    = 'get',
      POST   = 'post',
      PUT    = 'put',
      PATCH  = 'patch',
      DELETE = 'delete';

export default {
    async request(url, method = 'get', data = {}, params = {}, options = {}) {
        let response = {};
        const api_key = _.get(options, 'api_key', process.env.VUE_APP_API_KEY)
        const token = _.get(options, 'token', _.get(store.state.user, 'access_token', ''));

        try {
            let result = await axios({
                url: url,
                method: method,
                params: params,
                data: data,
                headers: {
                    'X-API-Key': api_key,
                    'Authorization': _.isNil(token) ? '' : ('Bearer ' + token)
                },
            });

            response = {
                ...result.data,
                statusCode: result.status,
                statusText: result.statusText
            }
        } catch (error) {
            response['error'] = error;
        }

        return response;
    },
    async [GET](url, params, options = {}) {
        return await this.request(url, GET, {}, params, options);
    },
    async [POST](url, data, params = {}, options = {}) {
        return await this.request(url, POST, data, params, options);
    },
    async [PUT](url, data, params = {}, options = {}) {
        return await this.request(url, PUT, data, params, options);
    },
    async [PATCH](url, data, params = {}, options = {}) {
        return await this.request(url, PATCH, data, params, options);
    },
    async [DELETE](url, params, options = {}) {
        return await this.request(url, DELETE, {}, params, options);
    },
    async upload(url, data, progress, options) {
        let response = {};
        const api_key = _.get(options, 'api_key', process.env.VUE_APP_API_KEY)
        const token = _.get(options, 'token', _.get(store.state.user, 'access_token', ''));

        let formData = new FormData();
        formData.append('file', _.get(data, 'file', ''));
        if(_.has(data, 'upload_dir'))
            formData.append('upload_dir', _.get(data, 'upload_dir', ''));

        try {
            let result = await axios.post(url, formData, {
                headers: {
                    'X-API-Key': api_key,
                    'Authorization': _.isNil(token) ? '' : ('Bearer ' + token),
                    'Content-type': 'multipart/form-data'
                },
                onUploadProgress: progress
            });
            response = {
                ...result.data,
                statusCode: result.status,
                statusText: result.statusText
            }
        } catch (error) {
            response['error'] = error;
        }

        return response;
    }
}