import Vue from 'vue'
import Vuex from 'vuex'

import user from './modules/user'
import partner from './modules/partner'
import product from './modules/product'
import code from './modules/code'
import short_code from './modules/short_code'
import license_type from './modules/license_type'
import license from './modules/license'
import order from './modules/order'
import version from './modules/version'
import stats from './modules/stats'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        user,
        partner,
        product,
        code,
        short_code,
        license_type,
        license,
        order,
        version,
        stats
    }
})