import Crud from '@/store/classes/crud'
const crud = new Crud({
    module: 'short_code'
})

export default {
    namespaced: true,
    state: {
        filter: {}
    },
    mutations: {
        ...crud.mutations
    },
    actions: {
        ...crud.actions
    },
    getters: {}
}